/**
  * Whirl
  *
  * @author jh3y
*/
@-webkit-keyframes whirl {
  0% {
    -webkit-transform: scale(0) rotate(-45deg) translateY(0);
            transform: scale(0) rotate(-45deg) translateY(0); }
  50% {
    -webkit-transform: scale(1.25) rotate(960deg) translateY(100%);
            transform: scale(1.25) rotate(960deg) translateY(100%); }
  100% {
    -webkit-transform: scale(0) rotate(2000deg) translateY(0);
            transform: scale(0) rotate(2000deg) translateY(0); } }
@keyframes whirl {
  0% {
    -webkit-transform: scale(0) rotate(-45deg) translateY(0);
            transform: scale(0) rotate(-45deg) translateY(0); }
  50% {
    -webkit-transform: scale(1.25) rotate(960deg) translateY(100%);
            transform: scale(1.25) rotate(960deg) translateY(100%); }
  100% {
    -webkit-transform: scale(0) rotate(2000deg) translateY(0);
            transform: scale(0) rotate(2000deg) translateY(0); } }

.whirl:before {
  -webkit-animation: whirl 2s infinite linear;
          animation: whirl 2s infinite linear;
  background-color: var(--primary);
  border-radius: 100%;
  content: '';
  height: 30px;
  width: 30px; }
